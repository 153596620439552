import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  NumberField,
  Show,
  NumberInput,
  Filter,
  DateInput,
  ReferenceInput,
  FileInput,
  FileField,
  TabbedShowLayout,
  Tab,
  AutocompleteInput,
  required,
} from 'react-admin'
import Icon from '@material-ui/icons/AttachMoney'
import HiddenUserInput from './HiddenUserInput'
import MultiDateField from '../fields/MultiDateField'
import ResourceField from '../fields/ResourceField'
import Attachments from './Attachments'
import nameFilterToQuery from '../utils/nameFilterToQuery'
export const SaleIcon = Icon

const SaleFilter = props => (
  <Filter {...props}>
    <TextInput source="bankTransaction.name_contains" label="Name" alwaysOn />
    <TextInput source="id_contains" label="ID" />
    <ReferenceInput
      label="Product"
      source="product.id"
      reference="Product"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput />
    </ReferenceInput>
    <ReferenceInput
      label="Type"
      source="type.id"
      reference="SaleType"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput />
    </ReferenceInput>
    <NumberInput source="quantity" />
    <NumberInput label="Amount" source="bankTransaction.amount" />
    <TextInput source="bankTransaction.description_contains" label="Description" />
    <ReferenceInput
      label="Product"
      source="product.id"
      reference="Product"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput />
    </ReferenceInput>
  </Filter>
)

export const SaleList = props => {
  return (
    <List title="Sales" {...props} filters={<SaleFilter />}>
      <Datagrid rowClick="show">
        <TextField source="bankTransaction.name" label="Name" />
        <TextField source="type.name" label="Type" />
        <TextField source="quantity" />
        <NumberField
          label="Amount"
          source="bankTransaction.amount"
          options={{ style: 'currency', currency: 'USD' }}
        />
        <TextField source="bankTransaction.description" label="Description" />
        <MultiDateField source="bankTransaction.date" label="Date" />
        <ResourceField
          reference="Product"
          source="product.name"
          target="product.id"
          label="Product"
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const SaleTitle = ({ record }) => {
  return <span>Sale {record ? `"${record.bankTransaction.name}"` : ''}</span>
}

export const SaleEdit = props => (
  <Edit title={<SaleTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="bankTransaction.name" validate={required()} label="Name" />
      <ReferenceInput
        label="Type"
        source="type.id"
        reference="SaleType"
        filterToQuery={nameFilterToQuery}
        validate={required()}
      >
        <AutocompleteInput />
      </ReferenceInput>
      <NumberInput source="quantity" validate={required()} />
      <NumberInput
        label="Amount"
        source="bankTransaction.amount"
        validate={required()}
      />
      <TextInput multiline source="bankTransaction.description" label="Description" />
      <DateInput source="bankTransaction.date" label="Date" validate={required()} />
      <ReferenceInput
        label="Product"
        source="product.id"
        reference="Product"
        filterToQuery={nameFilterToQuery}
        validate={required()}
      >
        <AutocompleteInput />
      </ReferenceInput>
      <FileInput source="uploads" label="Attachments" multiple>
        <FileField source="url" title="name" />
      </FileInput>
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const SaleShow = props => (
  <Show title={<SaleTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="bankTransaction.name" label="Name" />
        <TextField source="type.name" label="Type" />
        <NumberField source="quantity" />
        <NumberField
          label="Amount"
          source="bankTransaction.amount"
          options={{ style: 'currency', currency: 'USD' }}
        />
        <TextField source="bankTransaction.description" label="Description" />
        <MultiDateField source="bankTransaction.date" label="Date" />
        <ResourceField
          reference="Product"
          source="product.name"
          target="product.id"
          label="Product"
        />
        <ResourceField
          reference="BankTransaction"
          source="bankTransaction.name"
          target="bankTransaction.id"
          label="Bank transaction"
        />
      </Tab>
      <Tab label="Attachments" path="attachments">
        <Attachments />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
