import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import { merge } from 'lodash'

export default polyglotI18nProvider(() => {
  return merge({}, englishMessages, {
    resources: {
      BankTransaction: {
        empty: 'No bank transactions yet',
      },
      ExpenseType: {
        empty: 'No expense types yet',
      },
      Inventory: {
        empty: 'No inventory yet',
      },
      SaleType: {
        empty: 'No sale types yet',
      },
    },
  })
})
