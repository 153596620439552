import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  NumberField,
  SelectField,
  Show,
  SelectInput,
  NumberInput,
  Filter,
  DateInput,
  TabbedShowLayout,
  Tab,
  FileInput,
  FileField,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin'
import Icon from '@material-ui/icons/Assignment'
import { map } from 'lodash'
import optionText from '../utils/optionText'
import HiddenUserInput from './HiddenUserInput'
import MultiDateField from '../fields/MultiDateField'
import Attachments from './Attachments'
import nameFilterToQuery from '../utils/nameFilterToQuery'
import ResourceField from '../fields/ResourceField'
export const InventoryIcon = Icon

const InventoryFilter = props => (
  <Filter {...props}>
    <TextInput source="name_contains" label="Name" alwaysOn />
    <TextInput source="id_contains" label="ID" />
    <ReferenceInput
      label="Product"
      source="product.id"
      reference="Product"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput/>
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={InventoryType}
      optionText={optionText}
    />
    <NumberInput source="quantity" />
    <TextInput source="description_contains" label="Description" />
    <DateInput source="date" validate={required()} />
  </Filter>
)

export const InventoryList = props => {
  return (
    <List title="Inventory" {...props} filters={<InventoryFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="product.name" label="Product" />
        <SelectField
          source="status"
          choices={InventoryType}
          optionText={optionText}
        />
        <TextField source="quantity" />
        <TextField source="description" />
        <MultiDateField source="date" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const InventoryTitle = ({ record }) => {
  return <span>Inventory {record ? `"${record.name}"` : ''}</span>
}

const InventoryType = map(
  ['Ordered', 'Received', 'SentToFulfillment', 'Returns', 'Other'],
  id => ({ id }),
)

export const InventoryEdit = props => (
  <Edit title={<InventoryTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <ReferenceInput
        label="Product"
        source="product.id"
        reference="Product"
        filterToQuery={nameFilterToQuery}
        validate={required()}
      >
        <AutocompleteInput/>
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={InventoryType}
        optionText={optionText}
        validate={required()}
      />
      <NumberInput source="quantity" validate={required()} />
      <TextInput multiline source="description" />
      <DateInput source="date" validate={required()} />
      <FileInput source="uploads" label="Attachments" multiple>
        <FileField source="url" title="name" />
      </FileInput>
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const InventoryShow = props => (
  <Show title={<InventoryTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <ResourceField
          reference="Product"
          source="product.name"
          target="product.id"
          label="Product"
        />
        <SelectField
          source="status"
          choices={InventoryType}
          optionText={optionText}
        />
        <NumberField source="quantity" />
        <TextField source="description" />
        <MultiDateField source="date" />
      </Tab>
      <Tab label="Attachments" path="attachments">
        <Attachments />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const InventoryCreate = props => {
  return (
    <Create title="Create Inventory" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={required()} />
        <ReferenceInput
          label="Product"
          source="product.id"
          reference="Product"
          filterToQuery={nameFilterToQuery}
        >
          <AutocompleteInput/>
        </ReferenceInput>
        <SelectInput
          source="status"
          choices={InventoryType}
          optionText={optionText}
        />
        <NumberInput source="quantity" />
        <TextInput multiline source="description" />
        <DateInput source="date" validate={required()} />
        <FileInput source="uploads" label="Attachments" multiple>
          <FileField source="url" title="name" />
        </FileInput>
        <HiddenUserInput {...props} />
      </SimpleForm>
    </Create>
  )
}
