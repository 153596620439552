import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Title, SimpleShowLayout, TextField } from 'react-admin'
import ResourceField from '../fields/ResourceField'

export default class Settings extends Component {
  render() {
    return (
      <Card>
        <Title title="Settings" />
        <CardContent>
          <SimpleShowLayout
            record={{
              name: window.localStorage.getItem('name'),
              email: window.localStorage.getItem('email'),
            }}
          >
            <TextField source="name" />
            <TextField source="email" />
            <ResourceField reference="SaleType" label="Sale Types" />
            <ResourceField reference="ExpenseType" label="Expense Types" />
            <ResourceField reference="Product" label="Products" />
          </SimpleShowLayout>
        </CardContent>
      </Card>
    )
  }
}
