import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import { DateField } from 'react-admin'

const MultiDateField = props => {
  const { source, source2, record } = props
  const finalSource = get(record, source) ? source : source2
  const data = get(record, finalSource)
  let newRecord = {
    [finalSource]: moment(data).toISOString(),
  }
  return (
    <DateField
      {...props}
      record={newRecord}
      source={finalSource}
      label="Date"
    />
  )
}

MultiDateField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  source2: PropTypes.string,
}

MultiDateField.defaultProps = {
  addLabel: true,
}

export default MultiDateField
