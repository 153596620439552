import React from 'react'
import { Datagrid, TextField, EditButton, UrlField } from 'react-admin'
import MultiDateField from '../fields/MultiDateField'

export default props => {
  const data = props.record.attachments.reduce(
    (acc, attachment) => ({
      ...acc,
      [attachment.id]: attachment,
    }),
    {},
  )
  return (
    <Datagrid
      {...props}
      data={data}
      ids={props.record.attachmentsIds}
      currentSort={{}}
      basePath="/File"
    >
      <TextField source="name" />
      <UrlField source="url" label="URL" target="_blank" />
      <MultiDateField source="createdAt" />
      <EditButton basePath="/File" />
    </Datagrid>
  )
}
