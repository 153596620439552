import React from 'react'
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  Show,
  SimpleShowLayout,
  UrlField,
  List,
  Datagrid,
  EditButton,
  Filter,
  required,
} from 'react-admin'

const FileFilter = props => (
  <Filter {...props}>
    <TextInput source="name_contains" label="Name" alwaysOn />
    <TextInput source="id_contains" label="ID" />
    <TextInput source="url_contains" label="URL" />
  </Filter>
)

export const FileList = props => {
  return (
    <List title="Attachments" {...props} filters={<FileFilter />}>
      <Datagrid>
        <TextField source="name" />
        <UrlField source="url" label="URL" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const FileTitle = ({ record }) => {
  return <span>File {record ? `"${record.name}"` : ''}</span>
}

export const FileEdit = props => (
  <Edit title={<FileTitle />} {...props}>
    <SimpleForm
      redirect={() => {
        debugger
        return '/'
      }}
    >
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
)

export const FileShow = props => (
  <Show title={<FileTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <UrlField source="url" label="URL" target="_blank" />
    </SimpleShowLayout>
  </Show>
)
