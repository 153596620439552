import { InMemoryCache } from 'apollo-boost'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'

export default new ApolloClient({
  link: ApolloLink.from([
    new ApolloLink((operation, forward) => {
      const token = localStorage.getItem('authToken')

      if (token) {
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        })
      }

      return forward(operation)
    }),
    new createUploadLink({ uri: process.env.BACKEND_ENDPOINT }),
  ]),
  cache: new InMemoryCache(),
})
