import { GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE } from 'react-admin'
import { get, reduce, set } from 'lodash'
import bankFragment from '../fragments/bankFragment.graphql'
import bankTransactionFragment from '../fragments/bankTransactionFragment.graphql'
import expenseFragment from '../fragments/expenseFragment.graphql'
import inventoryFragment from '../fragments/inventoryFragment.graphql'
import reportFragment from '../fragments/reportFragment.graphql'
import saleFragment from '../fragments/saleFragment.graphql'

const buildOverrideQuery = (resourceType, fragment) =>
  reduce(
    [GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE],
    (acc, fetchType) => set(acc, fetchType, fragment),
    {},
  )
const overridenQueries = reduce(
  [
    { resourceType: 'Bank', fragment: bankFragment },
    { resourceType: 'BankTransaction', fragment: bankTransactionFragment },
    { resourceType: 'Expense', fragment: expenseFragment },
    { resourceType: 'Inventory', fragment: inventoryFragment },
    { resourceType: 'Report', fragment: reportFragment },
    { resourceType: 'Sale', fragment: saleFragment },
  ],
  (acc, { resourceType, fragment }) =>
    set(acc, resourceType, buildOverrideQuery(resourceType, fragment)),
  {},
)
export default buildQuery => introspectionResults => (
  fetchType,
  resourceName,
  params,
) => {
  const fragment = get(overridenQueries, `${resourceName}.${fetchType}`)

  return buildQuery(introspectionResults)(
    fetchType,
    resourceName,
    params,
    fragment,
  )
}
