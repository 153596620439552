import React, { Component } from 'react'
import { css } from '@emotion/core'
import buildOpenCrudProvider, { buildQuery } from '@toosick/ra-data-opencrud'
import { createBrowserHistory } from 'history'
import { Admin, Resource } from 'react-admin'
import { RotateLoader } from 'react-spinners'
import { ApolloProvider, compose } from 'react-apollo'
import { ExpenseList, ExpenseEdit, ExpenseShow, ExpenseIcon } from './expense'
import {
  InventoryList,
  InventoryEdit,
  InventoryShow,
  InventoryCreate,
  InventoryIcon,
} from './inventory'
import { SaleList, SaleEdit, SaleShow, SaleIcon } from './sale'
import { BankList, BankEdit, BankShow, BankCreate, BankIcon } from './bank'
import {
  ReportList,
  ReportEdit,
  ReportShow,
  ReportCreate,
  ReportIcon,
} from './report'
import {
  BankTransactionList,
  BankTransactionEdit,
  BankTransactionShow,
  BankTransactionCreate,
  BankTransactionIcon,
} from './bankTransaction'
import { FileEdit, FileShow, FileList } from './file'
import {
  SaleTypeEdit,
  SaleTypeShow,
  SaleTypeList,
  SaleTypeCreate,
} from './saleType'
import {
  ExpenseTypeEdit,
  ExpenseTypeShow,
  ExpenseTypeList,
  ExpenseTypeCreate,
} from './expenseType'
import { ProductEdit, ProductShow, ProductList, ProductCreate } from './product'
import authProvider from './authProvider'
import LoginPage from './LoginPage'
import apolloClient from './services/apolloClient'
import customRoutes from './routes'
import Menu from './Menu'
import enhanceBuildQuery from '../utils/enhanceBuildQuery'

import './App.css'
import addUploadFeature from './services/addUploadFeature'
import addSaleExpenseFeature from './services/addSaleExpenseFeature'
import i18nProvider from './i18nProvider'

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataProvider: null,
    }
  }
  componentDidMount() {
    buildOpenCrudProvider({
      client: apolloClient,
      buildQuery: enhanceBuildQuery(buildQuery),
    }).then(dataProvider =>
      this.setState({
        dataProvider: compose(
          addUploadFeature,
          addSaleExpenseFeature,
        )(dataProvider),
      }),
    )
  }
  render() {
    window.initialHash = window.location.hash
    const { dataProvider } = this.state

    if (!dataProvider) {
      return (
        <div styleName="loader-wrapper">
          <RotateLoader
            color={'#2196f3'}
            css={css`
              display: block;
              right: 0;
              bottom: 0;
              border-color: red;
            `}
          />
        </div>
      )
    }
    return (
      <ApolloProvider client={apolloClient}>
        <Admin
          dataProvider={dataProvider}
          title="Accounting"
          authProvider={authProvider}
          loginPage={LoginPage}
          history={createBrowserHistory()}
          customRoutes={customRoutes}
          menu={Menu}
          i18nProvider={i18nProvider}
        >
          <Resource
            name="Bank"
            options={{ label: 'Banks' }}
            list={BankList}
            edit={BankEdit}
            show={BankShow}
            create={BankCreate}
            icon={BankIcon}
          />
          <Resource
            name="BankTransaction"
            options={{ label: 'Transactions' }}
            list={BankTransactionList}
            edit={BankTransactionEdit}
            show={BankTransactionShow}
            create={BankTransactionCreate}
            icon={BankTransactionIcon}
          />
          <Resource
            name="Sale"
            options={{ label: 'Sales' }}
            list={SaleList}
            edit={SaleEdit}
            show={SaleShow}
            icon={SaleIcon}
          />
          <Resource
            name="Expense"
            options={{ label: 'Expenses' }}
            list={ExpenseList}
            edit={ExpenseEdit}
            show={ExpenseShow}
            icon={ExpenseIcon}
          />
          <Resource
            name="Inventory"
            options={{ label: 'Inventory' }}
            list={InventoryList}
            edit={InventoryEdit}
            show={InventoryShow}
            create={InventoryCreate}
            icon={InventoryIcon}
          />
          <Resource
            name="Report"
            options={{ label: 'Reports' }}
            list={ReportList}
            edit={ReportEdit}
            show={ReportShow}
            create={ReportCreate}
            icon={ReportIcon}
          />
          <Resource name="User" />
          <Resource
            name="File"
            edit={FileEdit}
            show={FileShow}
            list={FileList}
          />
          <Resource
            name="SaleType"
            edit={SaleTypeEdit}
            show={SaleTypeShow}
            list={SaleTypeList}
            create={SaleTypeCreate}
          />
          <Resource
            name="ExpenseType"
            edit={ExpenseTypeEdit}
            show={ExpenseTypeShow}
            list={ExpenseTypeList}
            create={ExpenseTypeCreate}
          />
          <Resource
            name="Product"
            edit={ProductEdit}
            show={ProductShow}
            list={ProductList}
            create={ProductCreate}
          />
        </Admin>
      </ApolloProvider>
    )
  }
}
