import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  NumberField,
  SelectField,
  SelectInput,
  NumberInput,
  Filter,
  DateInput,
  ReferenceInput,
  TabbedShowLayout,
  Tab,
  FileInput,
  FileField,
  FormDataConsumer,
  ShowController,
  ShowView,
  AutocompleteInput,
  required,
  SaveButton,
  Toolbar,
} from 'react-admin'
import Icon from '@material-ui/icons/Transform'
import { map, get } from 'lodash'
import optionText from '../utils/optionText'
import HiddenUserInput from './HiddenUserInput'
import ResourceField from '../fields/ResourceField'
import MultiDateField from '../fields/MultiDateField'
import Attachments from './Attachments'
import nameFilterToQuery from '../utils/nameFilterToQuery'
export const BankTransactionIcon = Icon

const BankTransactionFilter = props => (
  <Filter {...props}>
    <TextInput source="name_contains" label="Name" alwaysOn />
    <TextInput source="id_contains" label="ID" />
    <SelectInput
      source="type"
      choices={BankTransactionType}
      optionText={optionText}
    />
    <ReferenceInput
      label="Sale Type"
      source="sale.type.id"
      reference="SaleType"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput />
    </ReferenceInput>
    <ReferenceInput
      label="Expense Type"
      source="expense.type.id"
      reference="ExpenseType"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput />
    </ReferenceInput>
    <ReferenceInput
      label="Sale Product"
      source="sale.product.id"
      reference="Product"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput />
    </ReferenceInput>
    <ReferenceInput
      label="Expense Product"
      source="expense.product.id"
      reference="Product"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput />
    </ReferenceInput>
    <ReferenceInput
      label="Product"
      source="product.id"
      reference="Product"
      filterToQuery={nameFilterToQuery}
    >
      <AutocompleteInput />
    </ReferenceInput>
    <NumberInput source="amount" />
    <NumberInput source="sale.quantity" label="Sale Quantity" />
    <TextInput source="description_contains" label="Description" />
    <DateInput source="date" />
  </Filter>
)

export const BankTransactionList = props => {
  return (
    <List
      title="Bank Transactions"
      {...props}
      filters={<BankTransactionFilter />}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <SelectField
          source="type"
          choices={BankTransactionType}
          optionText={optionText}
        />
        <ResourceField
          reference="Bank"
          source="bank.name"
          target="bank.id"
          label="Bank"
        />
        <NumberField
          source="amount"
          options={{ style: 'currency', currency: 'USD' }}
        />
        <TextField source="description" />
        <MultiDateField source="date" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const BankTransactionTitle = ({ record }) => {
  return <span>Bank Transaction {record ? `"${record.name}"` : ''}</span>
}

const BankTransactionType = map(
  ['Sale', 'Expense', 'Payment', 'Deposit', 'Withdraw', 'Other'],
  id => ({ id }),
)

const productShow = ({ formData, ...rest }) =>
  'Sale' !== formData.type && 'Expense' !== formData.type ? (
    <ReferenceInput
      label="Product"
      source="product.id"
      reference="Product"
      {...rest}
      filterToQuery={nameFilterToQuery}
      allowEmpty={true}
    >
      <AutocompleteInput />
    </ReferenceInput>
  ) : null

const CustomSaveButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm()

  const handleClick = useCallback(() => {
    const record = form.getState().values

    // Connect user id to sale/expense creation
    if (record.type === 'Sale') {
      form.change(
        `sale${!record?.sale?.id ? '.create' : ''}.user.id`,
        localStorage.getItem('id'),
      )
    } else if (record.type === 'Expense') {
      form.change(
        `expense${!record?.expense?.id ? '.create' : ''}.user.id`,
        localStorage.getItem('id'),
      )
    }

    // Delete sale if type not sale anymore
    if (record.type !== 'Sale' && record?.sale?.id) {
      form.change('sale', { delete: true })
    }

    // Delete expense if type not expense anymore
    if (record.type !== 'Expense' && record?.expense?.id) {
      form.change('expense', { delete: true })
    }

    // Disconnect product if type is sale/expense
    if (['Sale', 'Expense'].includes(record.type) && record?.product?.id) {
      form.change('product', { disconnect: true })
    }

    handleSubmitWithRedirect('list')
  }, [form])

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
}
const BankTransactionToolbar = props => (
  <Toolbar {...props}>
    <CustomSaveButton label="Save" submitOnEnter={false} />
  </Toolbar>
)
export const BankTransactionEdit = props => (
  <Edit title={<BankTransactionTitle />} {...props}>
    <SimpleForm redirect="list" toolbar={<BankTransactionToolbar />}>
      <TextInput source="name" validate={required()} />
      <SelectInput
        source="type"
        label="Type"
        choices={BankTransactionType}
        optionText={optionText}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          'Sale' === formData.type && (
            <ReferenceInput
              label="Sale Type"
              source={`sale${
                !formData?.sale?.id ? '.create' : ''
              }.type.id`}
              reference="SaleType"
              {...rest}
              filterToQuery={nameFilterToQuery}
              validate={required()}
            >
              <AutocompleteInput />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          'Expense' === formData.type && (
            <ReferenceInput
              label="Expense Type"
              source={`expense${
                !formData?.expense?.id ? '.create' : ''
              }.type.id`}
              reference="ExpenseType"
              {...rest}
              filterToQuery={nameFilterToQuery}
              validate={required()}
            >
              <AutocompleteInput />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          'Sale' === formData.type && (
            <NumberInput
              source={`sale${
                !formData?.sale?.id ? '.create' : ''
              }.quantity`}
              label="Sale Quantity"
              {...rest}
              validate={required()}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          'Sale' === formData.type && (
            <ReferenceInput
              label="Sale Product"
              source={`sale${
                !formData?.sale?.id ? '.create' : ''
              }.product.id`}
              reference="Product"
              {...rest}
              filterToQuery={nameFilterToQuery}
              allowEmpty={true}
            >
              <AutocompleteInput />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          'Expense' === formData.type && (
            <ReferenceInput
              label="Expense Product"
              source={`expense${
                !formData?.expense?.id ? '.create' : ''
              }.product.id`}
              reference="Product"
              {...rest}
              filterToQuery={nameFilterToQuery}
              allowEmpty={true}
            >
              <AutocompleteInput />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>{productShow}</FormDataConsumer>
      <ReferenceInput
        label="Bank"
        source="bank.id"
        reference="Bank"
        filterToQuery={nameFilterToQuery}
        validate={required()}
      >
        <AutocompleteInput />
      </ReferenceInput>
      <NumberInput source="amount" validate={required()} />
      <TextInput multiline source="description" />
      <DateInput source="date" validate={required()} />
      <FileInput source="uploads" label="Attachments" multiple>
        <FileField source="url" title="name" />
      </FileInput>
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const BankTransactionShow = props => (
  <ShowController {...props}>
    {controllerProps => {
      return (
        <ShowView
          {...props}
          {...controllerProps}
          title={<BankTransactionTitle />}
        >
          <TabbedShowLayout>
            <Tab label="summary">
              <TextField source="id" />
              <TextField source="name" />
              <SelectField
                source="type"
                choices={BankTransactionType}
                optionText={optionText}
                label="Bank Transaction Type"
              />
              {get(controllerProps, 'record.sale') && (
                <TextField source="sale.type.name" label="Sale Type" />
              )}
              {get(controllerProps, 'record.expense') && (
                <TextField source="expense.type.name" label="Expense Type" />
              )}
              {get(controllerProps, 'record.sale') && (
                <NumberField source="sale.quantity" label="Sale quantity" />
              )}
              <ResourceField
                reference="Bank"
                source="bank.name"
                target="bank.id"
                label="Bank"
              />
              {get(controllerProps, 'record.sale') && (
                <ResourceField reference="Sale" target="sale.id" label="Sale" />
              )}
              {get(controllerProps, 'record.expense') && (
                <ResourceField
                  reference="Expense"
                  source="expense.name"
                  target="expense.id"
                  label="Expense"
                />
              )}
              {get(controllerProps, 'record.product') && (
                <ResourceField
                  reference="Product"
                  source="product.name"
                  target="product.id"
                  label="Product"
                />
              )}
              {get(controllerProps, 'record.sale.product') && (
                <ResourceField
                  reference="Product"
                  source="sale.product.name"
                  target="sale.product.id"
                  label="Product"
                />
              )}
              {get(controllerProps, 'record.expense.product') && (
                <ResourceField
                  reference="Product"
                  source="expense.product.name"
                  target="expense.product.id"
                  label="Product"
                />
              )}
              <NumberField
                source="amount"
                options={{ style: 'currency', currency: 'USD' }}
              />
              <TextField source="description" />
              <MultiDateField source="date" />
            </Tab>
            <Tab label="Attachments" path="attachments">
              <Attachments />
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      )
    }}
  </ShowController>
)

export const BankTransactionCreate = props => {
  return (
    <Create title="Create Bank Transaction" {...props}>
      <SimpleForm redirect="list" toolbar={<BankTransactionToolbar />}>
        <TextInput source="name" validate={required()} />
        <SelectInput
          source="type"
          label="Type"
          choices={BankTransactionType}
          optionText={optionText}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            'Sale' === formData.type && (
              <ReferenceInput
                label="Sale Type"
                source="sale.create.type.id"
                reference="SaleType"
                {...rest}
                filterToQuery={nameFilterToQuery}
                validate={required()}
              >
                <AutocompleteInput />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            'Expense' === formData.type && (
              <ReferenceInput
                label="Expense Type"
                source="expense.create.type.id"
                reference="ExpenseType"
                {...rest}
                filterToQuery={nameFilterToQuery}
                validate={required()}

              >
                <AutocompleteInput />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            'Sale' === formData.type && (
              <NumberInput
                source="sale.create.quantity"
                label="Sale Quantity"
                {...rest}
                validate={required()}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            'Sale' === formData.type && (
              <ReferenceInput
                label="Product"
                source="sale.create.product.id"
                reference="Product"
                {...rest}
                filterToQuery={nameFilterToQuery}
                allowEmpty={true}

              >
                <AutocompleteInput/>
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            'Expense' === formData.type && (
              <ReferenceInput
                label="Product"
                source="expense.create.product.id"
                reference="Product"
                {...rest}
                filterToQuery={nameFilterToQuery}
                allowEmpty={true}
              >
                <AutocompleteInput />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>{productShow}</FormDataConsumer>
        <ReferenceInput
          label="Bank"
          source="bank.id"
          reference="Bank"
          filterToQuery={nameFilterToQuery}
          validate={required()}
        >
          <AutocompleteInput />
        </ReferenceInput>
        <NumberInput source="amount" validate={required()} />
        <TextInput multiline source="description" />
        <DateInput source="date" validate={required()} />
        <FileInput source="uploads" label="Attachments" multiple>
          <FileField source="url" title="name" />
        </FileInput>
        <HiddenUserInput {...props} />
      </SimpleForm>
    </Create>
  )
}
