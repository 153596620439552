import React from 'react'
import { get } from 'lodash'
import netlifyIdentity from 'netlify-identity-widget'
import { userLogin as userLoginAction } from 'react-admin'
import { connect } from 'react-redux'
import { withApollo, compose } from 'react-apollo'
import AuthenticateUser from '../operations/AuthenticateUser.graphql'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    if (!window.netlifyIdentity) {
      if (window.initialHash) {
        window.location.hash = window.initialHash
      }
      netlifyIdentity.init({
        container: '#netlify-modal',
        APIUrl: process.env.NETLIFY_IDENTITY_ENDPOINT,
      })
      netlifyIdentity.on('logout', () => {
        console.log('logout')
      })
      netlifyIdentity.on('open', () => {
        console.log('open')
        netlifyIdentity.logout()
      })
      netlifyIdentity.on('close', () => {
        console.log('close')
        if (!netlifyIdentity.isUnmounting) {
          netlifyIdentity.open()
        }
        netlifyIdentity.isUnmounting = false
      })
      netlifyIdentity.on('login', this.login)
      window.netlifyIdentity = netlifyIdentity
    }
  }
  login = async () => {
    const { userLogin } = this.props
    const netlifyUser = netlifyIdentity.currentUser()
    console.log('netlifyUser', netlifyUser)
    const response = await this.props.client.query({
      query: AuthenticateUser,
      variables: {
        token: netlifyUser.token.access_token,
      },
    })
    const user = get(response, 'data.user')
    if (user) {
      console.log('user', user)
      window.localStorage.setItem('id', user.id)
      window.localStorage.setItem('name', user.name)
      window.localStorage.setItem('email', user.email)
      window.localStorage.setItem('role', '')
      window.localStorage.setItem('authToken', user.token)
      setTimeout(() => {
        userLogin(user)
      })
    }
  }
  componentDidMount() {
    console.log('componentDidMount')
    netlifyIdentity.open()
  }
  componentWillUnmount() {
    netlifyIdentity.isUnmounting = true
    netlifyIdentity.close()
  }
  render() {
    return null
  }
}
export default compose(
  connect(
    null,
    { userLogin: userLoginAction },
  ),
)(withApollo(LoginPage))
