import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  Show,
  Filter,
  SimpleShowLayout,
  Create,
  required,
} from 'react-admin'
import HiddenUserInput from './HiddenUserInput'

const SaleTypeFilter = props => (
  <Filter {...props}>
    <TextInput source="name_contains" label="Name" alwaysOn />
    <TextInput source="id_contains" label="ID" />
  </Filter>
)

export const SaleTypeList = props => {
  return (
    <List title="Sale Type" {...props} filters={<SaleTypeFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const SaleTypeTitle = ({ record }) => {
  return <span>Sale Type {record ? `"${record.name}"` : ''}</span>
}

export const SaleTypeEdit = props => (
  <Edit title={<SaleTypeTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const SaleTypeShow = props => (
  <Show title={<SaleTypeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
)

export const SaleTypeCreate = props => {
  return (
    <Create title="Create Sale Type" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={required()} />
        <HiddenUserInput {...props} />
      </SimpleForm>
    </Create>
  )
}
