import { merge } from 'lodash'
import { CREATE, UPDATE } from 'react-admin'
import { lowerCase } from 'change-case'

export default requestHandler => (type, resource, params) => {
  if (
    type === CREATE &&
    resource === 'BankTransaction' &&
    ['Sale', 'Expense'].includes(params.data.type)
  ) {
    return requestHandler(
      type,
      resource,
      merge({}, params, {
        data: {
          [lowerCase(params.data.type)]: {
            create: {
              name: params.data.name,
              amount: params.data.amount,
              description: params.data.description,
              date: params.data.date,
              user: {
                connect: params.data.user,
              },
            },
          },
        },
      }),
    )
  } else if (
    type === UPDATE &&
    resource === 'BankTransaction' &&
    ['Sale', 'Expense'].includes(params.data.type)
  ) {
    const finalData = merge({}, params, {
      data: {
        [lowerCase(params.data.type)]: {
          update: {
            name: params.data.name,
            amount: params.data.amount,
            description: params.data.description,
            date: params.data.date,
          },
        },
      },
    })
    return requestHandler(type, resource, finalData)
  }
  return requestHandler(type, resource, params)
}
