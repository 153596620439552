import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  Show,
  Filter,
  TabbedShowLayout,
  ReferenceManyField,
  NumberField,
  Tab,
  Pagination,
  FileInput,
  FileField,
  required,
} from 'react-admin'
import Icon from '@material-ui/icons/AccountBalance'
import HiddenUserInput from './HiddenUserInput'
import MultiDateField from '../fields/MultiDateField'
import ResourceField from '../fields/ResourceField'
import Attachments from './Attachments'
export const BankIcon = Icon

const BankFilter = props => (
  <Filter {...props}>
    <TextInput source="name_contains" label="Name" alwaysOn />
    <TextInput source="id_contains" label="ID" />
    <TextInput source="description_contains" label="Description" />
  </Filter>
)

export const BankList = props => {
  return (
    <List title="Banks" {...props} filters={<BankFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const BankTitle = ({ record }) => {
  return <span>Bank {record ? `"${record.name}"` : ''}</span>
}

export const BankEdit = props => (
  <Edit title={<BankTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput multiline source="description" />
      <FileInput source="uploads" label="Attachments" multiple>
        <FileField source="url" title="name" />
      </FileInput>
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const BankShow = props => (
  <Show title={<BankTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
      </Tab>
      <Tab label="Transactions" path="transactions">
        <ReferenceManyField
          reference="BankTransaction"
          target="bank.id"
          source="id"
          addLabel={false}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="type" />
            <ResourceField
              reference="Bank"
              source="bank.name"
              target="bank.id"
              label="Bank"
            />
            <NumberField
              source="amount"
              options={{ style: 'currency', currency: 'USD' }}
            />
            <TextField source="description" />
            <MultiDateField source="date" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Attachments" path="attachments">
        <Attachments />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const BankCreate = props => {
  return (
    <Create title="Create Bank" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={required()} />
        <TextInput multiline source="description" />
        <FileInput source="uploads" label="Attachments" multiple>
          <FileField source="url" title="name" />
        </FileInput>
        <HiddenUserInput {...props} />
      </SimpleForm>
    </Create>
  )
}
