import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import stopPropagation from '../utils/stopPropagation'

const ResourceField = ({
  source,
  basePath = '',
  record = {},
  target,
  reference,
}) => {
  basePath = reference ? `/${reference}` : basePath
  return (
    <Link
      to={`${basePath}${target ? `/${get(record, target)}/show` : ''}`}
      style={{ textDecoration: 'none' }}
      onClick={stopPropagation}
    >
      <span>{source ? get(record, source) : 'Show'}</span>
    </Link>
  )
}

ResourceField.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
}

ResourceField.defaultProps = {
  addLabel: true,
}

export default ResourceField
