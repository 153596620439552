import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin'
import netlifyIdentity from 'netlify-identity-widget'

export default (type, params) => {
  console.log(type, params)
  if (type === AUTH_LOGIN) {
    return Promise.resolve()
  }
  if (type === AUTH_LOGOUT) {
    netlifyIdentity.logout()
    localStorage.removeItem('authToken')
    localStorage.removeItem('role')
    localStorage.removeItem('id')
    localStorage.removeItem('name')
    localStorage.removeItem('email')

    return Promise.resolve()
  }
  if (type === AUTH_ERROR) {
    return Promise.resolve()
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('authToken')
      ? Promise.resolve()
      : Promise.reject()
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role')
    return Promise.resolve(role)
  }
  return Promise.reject('Unknown method')
}
