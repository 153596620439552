import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  SelectField,
  SelectInput,
  Filter,
  ReferenceInput,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  FileInput,
  FileField,
  ShowController,
  ShowView,
  FormDataConsumer,
  DateInput,
  SelectArrayInput,
  ReferenceArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  CardActions,
  AutocompleteInput,
  required,
} from 'react-admin'
import Icon from '@material-ui/icons/Assessment'
import GetApp from '@material-ui/icons/GetApp'
import Button from '@material-ui/core/Button'
import { map, get } from 'lodash'
import optionText from '../utils/optionText'
import HiddenUserInput from './HiddenUserInput'
import ChartField from '../fields/ChartField'
import Attachments from './Attachments'
import ReactToPdf from './ReactToPdf'
import nameFilterToQuery from '../utils/nameFilterToQuery'
import MultiDateField from '../fields/MultiDateField'
export const ReportIcon = Icon

const ReportFilter = props => (
  <Filter {...props}>
    <TextInput source="name_contains" label="Name" alwaysOn />
    <TextInput source="id_contains" label="ID" />
    <SelectInput source="type" choices={ReportType} optionText={optionText} />
    <TextInput source="description_contains" label="Description" />
  </Filter>
)

export const ReportList = props => {
  return (
    <List title="Reports" {...props} filters={<ReportFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <SelectField
          source="type"
          choices={ReportType}
          optionText={optionText}
        />
        <TextField source="description" />
        <MultiDateField source="createdAt" label="Created" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const ReportTitle = ({ record }) => {
  return <span>Report {record ? `"${record.name}"` : ''}</span>
}

const ReportType = map(
  [
    'TotalSales',
    'SalesPerProductMonthly',
    'SalesPerProductWeekly',
    // 'Expenses',
    // 'Inventory',
    // 'Payroll',
    // 'Withdrawls',
    // 'Deposits',
  ],
  id => ({ id }),
)
const ProductReportType = ['SalesPerProductMonthly', 'SalesPerProductWeekly']
const SalesReportType = [
  'TotalSales',
  'SalesPerProductMonthly',
  'SalesPerProductWeekly',
]

export const ReportEdit = props => (
  <Edit title={<ReportTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput multiline source="description" />
      <FileInput source="uploads" label="Attachments" multiple>
        <FileField source="url" title="name" />
      </FileInput>
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

const cardActionStyle = {
  zIndex: 2,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  padding: 0,
}

const ReportShowActions = ({ basePath, data }) => {
  return (
    <CardActions style={cardActionStyle}>
      <EditButton basePath={basePath} record={data} />
      <ReactToPdf
        targetRef={ref}
        filename={`report-${get(data, 'name')}.pdf`}
        options={{
          orientation: 'l',
        }}
      >
        {({ toPdf }) => (
          <Button
            color="primary"
            onClick={toPdf}
            size="small"
            style={{ padding: '5px 8px' }}
            disableTouchRipple
          >
            <GetApp />
            <span style={{ paddingLeft: '0.5em' }}>PDF</span>
          </Button>
        )}
      </ReactToPdf>
    </CardActions>
  )
}
const ref = React.createRef()
export const ReportShow = props => {
  return (
    <ShowController title={<ReportTitle />} {...props}>
      {controllerProps => (
        <ShowView
          {...props}
          {...controllerProps}
          actions={<ReportShowActions />}
        >
          <TabbedShowLayout>
            <Tab label="summary">
              <TextField source="id" />
              <TextField source="name" />
              <SelectField
                source="type"
                choices={ReportType}
                optionText={optionText}
              />
              {get(controllerProps.record, 'report.saleTypes') && (
                <ReferenceArrayField
                  label="Sale Types"
                  reference="SaleType"
                  source="report.saleTypes"
                >
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              )}
              {get(controllerProps.record, 'description') && (
                <TextField source="description" />
              )}
              <MultiDateField source="createdAt" label="Created" />
              {get(controllerProps.record, 'report.product.id') && (
                <ReferenceField
                  source="report.product.id"
                  reference="Product"
                  label="Product"
                >
                  <AutocompleteInput/>
                </ReferenceField>
              )}
              <ChartField source="report" />
            </Tab>
            <Tab label="Attachments" path="attachments">
              <Attachments />
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      )}
    </ShowController>
  )
}

export const ReportCreate = props => {
  return (
    <Create title="Create Report" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={required()} />
        <SelectInput
          source="type"
          choices={ReportType}
          optionText={optionText}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              ProductReportType.includes(formData.type) && (
                <ReferenceInput
                  {...rest}
                  source="report.product.id"
                  reference="Product"
                  label="Product"
                  filterToQuery={nameFilterToQuery}
                  validate={required()}
                >
                  <AutocompleteInput/>
                </ReferenceInput>
              )
            )
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              SalesReportType.includes(formData.type) && (
                <ReferenceArrayInput
                  label="Sale Types"
                  source="report.saleTypes"
                  reference="SaleType"
                  {...rest}
                  validate={required()}
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
              )
            )
          }}
        </FormDataConsumer>
        <TextInput multiline source="description" />
        <DateInput source="report.dateAfter" label="Date begin" />
        <DateInput source="report.dateBefore" label="Date end" />
        <FileInput source="uploads" label="Attachments" multiple>
          <FileField source="url" title="name" />
        </FileInput>
        <HiddenUserInput {...props} />
      </SimpleForm>
    </Create>
  )
}
