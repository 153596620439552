import { get, map, merge } from 'lodash'

export default requestHandler => (type, resource, params) => {
  const uploads = get(params, 'data.uploads')
  if (uploads) {
    const newParams = merge({}, params, {
      data: {
        uploads: map(uploads, ({ rawFile }) => {
          return rawFile
        }),
      },
    })
    return requestHandler(type, resource, newParams)
  }

  return requestHandler(type, resource, params)
}
