import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Line } from 'react-chartjs-2'
import usdFormat from '../utils/usdFormat'

const ChartField = props => {
  const {
    source,
    record: { type },
  } = props
  const report = get(props, ['record', ...source.split('.')])
  switch (type) {
    case 'TotalSales':
    case 'SalesPerProductMonthly':
    case 'SalesPerProductWeekly':
      return (
        <Line
          height={100}
          options={{
            responsive: true,
            tooltips: {
              mode: 'index',
              intersect: false,
              backgroundColor: 'rgba(0, 0, 0, 0.55)',
              callbacks: {
                label(tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex]
                  let label = dataset.label || ''

                  if (label) {
                    label += ': '
                  }
                  label +=
                    dataset.yAxisID === 'amount'
                      ? usdFormat(tooltipItem.yLabel)
                      : tooltipItem.yLabel
                  return label
                },
              },
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: report.xLabel,
                  },
                },
              ],
              yAxes: [
                {
                  id: 'amount',
                  display: true,
                  precision: 2,
                  scaleLabel: {
                    display: true,
                    labelString: 'Total sales',
                  },
                  ticks: {
                    callback: usdFormat,
                  },
                },
                {
                  id: 'count',
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Sales count',
                  },
                  ticks: {
                    stepSize: 1,
                  },
                },
              ],
            },
          }}
          data={{
            labels: report.labels,
            datasets: [
              {
                label: ' Total sales',
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgb(54, 162, 235)',
                data: report.amount,
                fill: false,
                yAxisID: 'amount',
              },
              {
                label: ' Sales count',
                backgroundColor: 'rgb(102,102,105)',
                borderColor: 'rgb(102,102,105)',
                data: report.count,
                fill: false,
                yAxisID: 'count',
              },
            ],
          }}
        />
      )
    default:
      return null
  }
}

ChartField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default ChartField
